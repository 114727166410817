import React from "react";
import './style.css'

class HomepageHeader extends React.Component {
    render() {
      return (
          <div class="homepageHeader">
              <h1>Welcome to Knowsy</h1>
              <h2>Learn something new. Every day.</h2>
          </div>
      )
    }
  }

export default HomepageHeader