import React from 'react';

class NotFound extends React.Component {
    render() {
        return (
            <h1>404 - Knowsy Page not found!</h1>
        )
    }
}

export default NotFound;